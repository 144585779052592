import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#373d3d',
            main: '#111717',
            dark: '#000000',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff66a4',
            main: '#ed2776',
            dark: '#b5004b',
            contrastText: '#fff',
        },
        text: {
            secondary: '#fff'
        },
    },
    typography: {
        body1: {
            fontFamily: "Roboto"
        },
    },
    spacing: 6
});

export default theme;