import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import {
    Typography,
    Container,
    Card,
    CardMedia,
    CardContent,
    Button,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
    Select,
    MenuItem
} from '@material-ui/core';
import Loader from "./Loader";

const Listings = ({ reward, handleChangeRegion }) => {
    const { isLoading, error, data } = useQuery(`/listing/regions?rewardIds=${reward.code}`);
    const [region, setRegion] = useState('');

    const handleChange = (e) => {
        setRegion(e.target.value);
        handleChangeRegion(e);
    }

    if (isLoading) return <Loader />;

    return (
        <>
            <Typography variant="h5" gutterBottom display="inline">
                VENUES
            </Typography>
            <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={region}
                onChange={handleChange}
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {data && data.map((d, i) => <MenuItem key={i} value={d.regionName}>{d.regionName}</MenuItem>)}
            </Select>
        </>
    );
}

export const Venues = ({ rewardId, region }) =>  {

    const { isLoading, error, data } = useQuery(`/listing/venues?rewardIds=${rewardId}&regions=${region}`);

    if (isLoading) return <Loader />;

    return (
        <>
            {data && data.map((venue, i) => <ul key={i} className="venues-listing-results fade-in01"><Venue data={venue} /></ul>)}
        </>
    );
}

const Venue = ({ data }) =>  {

    return (
        <li className="venue-listing">
            <div className="venue-listing-address">
                <Box fontSize="h6.fontSize">{data.venueName}</Box>
                <Box p={1}>
                    <Box>{data?.address1}</Box>
                    <Box>{data?.address2}</Box>
                    <Box>{data?.city}</Box>
                    <Box>{data?.county}</Box>
                    <Box>{data?.postcode}</Box >
                    <Box>{data?.phone}</Box >
                    <Box className="venue-offer-terms">
                        <Box>{data?.offerTerm1}</Box>
                        <Box>{data?.offerTerm3}</Box>
                        <Box>{data?.offerTerm4}</Box>
                    </Box>
                </Box>
            </div>
        </li>
    );
}

export default Listings;