import React from 'react';
import { Link } from 'react-router-dom';

import { Button, AppBar, Toolbar, Typography, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import banner from "../assets/img/app-banner-mobile-kids.jpg";
import desktopBanner from "../assets/img/app-banner-desktop-kids.jpg";

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none'
        },
        body: {
            margin: 0
        },
    },
    toolbarTitle: {
        textTransform: 'uppercase',
        fontWeight: 600,
        fontSize: '1.4rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '3rem'
        },
        textAlign: 'center',
        color: '#fff'
    },
    toolbarSubtitle: {
        fontWeight: 600,
        fontSize: '1.1rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.8rem'
        },
        textAlign: 'center',
        color: '#fff'
    },
    banner: {
        position: 'relative'
    },
    bannerImage: {
        width: '100%',
        marginBottom: '1rem'
    },
    desktopBannerImage: {
        width: '100%',
        marginBottom: '1rem'
    },
    bannerTextContainer: {
        position: 'absolute',
        width: '100%',
        left: 0,
        top: '25%',
        [theme.breakpoints.up('sm')]: {
            top: '35%'
        }
    }
}));

export default function NavMenu() {

    const classes = useStyles();

    return (
        
        <div className={classes.banner}>

            <Hidden implementation="css" mdUp>
                <img src={banner} className={classes.bannerImage} />
            </Hidden>
            <Hidden implementation="css" smDown>
                <img src={desktopBanner} className={classes.desktopBannerImage} />
            </Hidden>

            <div className={classes.bannerTextContainer}>
                <Typography variant="h4" className={classes.toolbarTitle}>
                    Your Exclusive Benefits
                </Typography>
                <Typography variant="h5" className={classes.toolbarSubtitle}>
                    Save over &pound;1000 on entertaining your kids!
                </Typography>
            </div>

        </div>
    );
}
