import authService from './components/api-authorization/AuthorizeService';
import { ApplicationPaths, QueryParameterNames } from './components/api-authorization/ApiAuthorizationConstants';

export const DefaultQueryFn = async ({ queryKey }) => {
    const token = await authService.getAccessToken();

    const response = await fetch(`/api${queryKey[0]}`, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });

    if (response.status === 401) {
        window.location.href = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
    }
    if (!response.ok) {
        throw new Error("Network response was not Ok");
    }

    return await response.json();
}

export const Post = async (url, postData) => {
    const token = await authService.getAccessToken();

    let options = {
        method: 'POST',
        headers: !token ? {} : {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };

    if (postData) {
        options["body"] = JSON.stringify(postData);
    }

    try {
        const response = await fetch(`/api${url}`, options);

        if (response.status === 401) {
            window.location.href = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
        }

        return await response.json().catch(() => response);
    }
    catch (err) {
        return await response;
    }
}