import React from 'react';
import { Link } from 'react-router-dom';

import { Button, AppBar, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LoginMenu from './api-authorization/LoginMenu';
import logo from "../assets/img/logo.png";

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none'
        },
        body: {
            margin: 0
        },
    },
    toolbar: {
        flexWrap: 'wrap',
        backgroundColor: '#222'
    },
    toolbarTitle: {
        flexGrow: 1,
        color: '#f5f5f5'
    },
    link: {
        margin: theme.spacing(1, 1.5),
        color: '#f5f5f5'
    },
    logo: {
        maxWidth: 80,
        marginRight: 10,
        marginTop: 7,
        [theme.breakpoints.up('sm')]: {
            maxWidth: 100
        }
    },
}));

export default function NavMenu() {

    const classes = useStyles();

    function NavBar() {
        return (
            <nav>
                <Button component={Link} to={'/'} className={classes.link}>
                    HOME
                </Button>
            </nav>);
    }

    return (
        <AppBar position="static" color="default" position="sticky" elevation={0} className={classes.appBar}>
            <Toolbar variant="dense" className={classes.toolbar}>
                <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                    <a href="/">
                        <img src={logo} alt="Super Kids Club" className={classes.logo} />
                    </a>
                </Typography>
                
                <NavBar />
                <LoginMenu />
            </Toolbar>
        </AppBar>
    );
}
