import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import {
    Typography,
    Container,
    Card,
    CardMedia,
    CardContent,
    Button,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
    Select,
    MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        }
    },
    root: {
        flexGrow: 1,
    },
    toolbarTitle: {
        flexGrow: 1,
        margin: '25px 20px 20px 20px',
        fontWeight: 'bold'
    },
    cardMedia: {
        minHeight: '520px',
        maxWidth: '100%'
    },
    redeemBtn: {
        marginTop: theme.spacing(1)
    },
    backBtn: {
        margin: '0 20px 20px 20px'
    }
}));

export default function FaqAndTncDialog({ data }) {

    const classes = useStyles();

    const [faqOpen, setFaqOpen] = useState(false);
    const [tncOpen, setTncOpen] = useState(false);

    return (
        <>
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        {
                            data.faqContent &&
                            (<Button key='faq' variant="contained" color="primary" onClick={() => setFaqOpen(p => !p)} fullWidth>
                                {data.faqHeader ?? "FAQ"}
                            </Button>)
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            data.termsContent &&
                            (<Button key='tnc' variant="contained" color="default" onClick={() => setTncOpen(p => !p)} fullWidth>
                                {data.termsHeader ?? "Terms"}
                            </Button>)
                        }
                    </Grid>
                </Grid>
            </div>

            <Dialog
                open={faqOpen}
                onClose={() => setFaqOpen(p => !p)}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>{data.faqHeader}</DialogTitle>
                <DialogContent>
                    <ReactMarkdown source={data.faqContent} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setFaqOpen(p => !p)} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={tncOpen}
                onClose={() => setTncOpen(p => !p)}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle>{data.termsHeader}</DialogTitle>
                <DialogContent>
                    <ReactMarkdown source={data.termsContent} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTncOpen(p => !p)} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
