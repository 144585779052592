import React from 'react';
import { Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none'
        },
        body: {
            margin: 0
        },
    },
    footerContainer: {
        color: '#fff',
        marginTop: 40,
        padding: '35px 10px',
        backgroundColor: '#222',
        textAlign: 'center',
        borderTop: 'solid #e51767 5px'
    },
    footerLink: {
        color: '#9ed1e6',
        textDecoration: 'none',
        '&:hover': { //Hover
            textDecoration: 'underline'
        }
    },
}));

export default function Footer() {

    const classes = useStyles();

    return (

        <Container maxWidth="xl" className={classes.footerContainer}>

            <Container maxWidth="lg">
                <Typography variant="caption" display="block">
                    &copy; {new Date().getFullYear()} Super Kids Club. All Rights Reserved. All trademarks are the property of their respective owners.
                </Typography>

                <Typography variant="subtitle2">
                    <br />
                    Need a hand? Have some questions? We're here for you! Call Us: <a href="tel:03301749417" className={classes.footerLink}>0330 174 9417</a> or Email Us: <a href="mailto:hello@super-kidsclub.co.uk" className={classes.footerLink}>hello@super-kidsclub.co.uk</a>. We're open Monday - Friday, 09:00 - 17:30 (exc. bank holidays).
                </Typography>
            </Container>

        </Container>

        
    );
}
