import React from 'react';
import { useQuery } from "react-query";
import { useHistory, generatePath } from 'react-router-dom';

import { Typography, Container, Grid, Card, CardMedia, CardHeader, CardContent, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


import Loader from './Loader';

const rewardThumbnailImagePath = 'assets/img/rewards-thumbnail/'

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        }
    },
    cardMedia: {
        height: '290px'
    },
    cardHeader: {
        backgroundColor: '#E61468',
        color: '#fff',
        textAlign: 'center',
        fontWeight: '600',
        padding: '7px'
    },
    banner: {

    }
}));

const Rewards = () => {

    const history = useHistory();
    const { isLoading, error, data: rewards } = useQuery(`/rewards/all`);

    const handleCardClick = (event) => {
        let reward = encodeURI(event.target.id);
        let url = generatePath("/details/:reward", { reward });
        history.push(url);
    }

    const classes = useStyles();

    if (isLoading) return <Loader/>;

    if (error) return <Error />;

    return (
        <Container maxWidth="lg" component="main">
            <Grid container spacing={5} alignItems="flex-end" >
                <Grid item key="free-entry-wb-hpstudiotour" xs={12} lg={6}>
                    <Card md={6}>
                        <CardActionArea onClick={handleCardClick}>
                            <CardHeader className={classes.cardHeader}
                                title="SPOTLIGHT REWARD"
                            />
                            <CardMedia className={classes.cardMedia}
                                image={"/assets/img/rewards-thumbnail/Harry_Potter_900x500.jpg"}
                                title={"Spotlight Reward"}
                                id={"free-entry-wb-hpstudiotour"}
                            />
                            <CardContent>
                                <Typography id="free-entry-wb-hpstudiotour" variant="button" component="p" align="center">
                                    One Free Entry To The Warner Bros Harry Potter Studio Tour
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item key="disneyplus-free" xs={12} lg={6}>
                    <Card md={6}>
                        <CardActionArea onClick={handleCardClick}>
                            <CardHeader className={classes.cardHeader}
                                title="SPOTLIGHT REWARD"
                            />
                            <CardMedia className={classes.cardMedia}
                                image={"/assets/img/rewards-thumbnail/Disney_plus_900x500.jpg"}
                                title={"Spotlight Reward"}
                                id={"disneyplus-free"}
                            />
                            <CardContent>
                                <Typography id="disneyplus-free" variant="button" component="p" align="center">
                                    Free Disney+ Annual Subscription (worth &pound;79.90)
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                {
                    rewards.map((r, i) => (r.id !== "free-entry-wb-hpstudiotour" && r.id !== "disneyplus-free") && <Reward key={i} data={r} handleCardClick={handleCardClick} classes={classes} />)
                }
            </Grid>
        </Container>
    );
}

const Reward = ({ data: r, handleCardClick, classes }) => {

    return (<Grid item key={r.id} xs={12} sm={6} lg={4}>
        <Card md={6}>
            <CardActionArea onClick={handleCardClick}>
                <CardMedia className={classes.cardMedia}
                    image={rewardThumbnailImagePath + r.smallImageUrl}
                    title={r.title}
                    id={r.id}
                />
                <CardContent>
                    <Typography id={r.id} variant="button" component="p" align="center">
                        {r.title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    </Grid>)
}

export default function () {

    const classes = useStyles();

    document.title = `Super Kids Club | Rewards`;
    
    return (
        <div>
            <Rewards />
        </div>
    );
}