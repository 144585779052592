import React from 'react';
import { Route, Switch } from 'react-router';
import { QueryClient, QueryClientProvider } from "react-query";

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import theme from './theme';
import './custom.css';

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import Error from './components/Error';
import Rewards from './components/Rewards';
import Layout  from './components/Layout';
import RewardDetails from './components/RewardDetails';
import { DefaultQueryFn } from './query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            queryFn: DefaultQueryFn,
            refetchOnWindowFocus: false,
            retry: false
        }
    }
});

export default function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Layout>
                    <Switch>
                        <AuthorizeRoute exact path='/' component={Rewards} />
                        <AuthorizeRoute path='/details/:reward' component={RewardDetails} />
                        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                        <AuthorizeRoute path='/' component={Error} />
                    </Switch>
                </Layout>
            </ThemeProvider>
        </QueryClientProvider>
    );
}
