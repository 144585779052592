import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Container, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import errorImage from '../assets/img/error-image.png';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginLeft: theme.spacing(20),
        },
        verticalAlign: 'middle',
        textAlign: 'center',
    },
    errorImage: {
        maxWidth: '100%',
        height: 'auto'
    }
}));

const Error = () => {

    const classes = useStyles();

    return (
        <Container>
            <Box display="flex" justifyContent="center">
                <img src={errorImage} className={classes.errorImage} />
            </Box>
            <Box m={5} className={classes.root}>
                <Typography variant="h5">
                    There has been an error, please return to the &nbsp;
                    <Link to="/" >home page</Link>
                    &nbsp;and try again.
                </Typography>
            </Box>
        </Container>
    );
}

export default Error;
