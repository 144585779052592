import { useEffect, useState } from 'react';
import authService from './../api-authorization/AuthorizeService';

export default function useAuthenticated() {
    const [auth, setAuth] = useState({ isAuthenticated: false, ready: false });

    useEffect(() => {
        const fetchData = async () => {
            const isAuthenticated = await authService.isAuthenticated();

            setAuth({ isAuthenticated: isAuthenticated, ready: true });
        }

        let subscription = authService.subscribe(() => fetchData());

        fetchData();    
            
        return () => authService.unsubscribe(subscription);

    }, [setAuth]);

    return auth;
}
