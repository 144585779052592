import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Hidden } from '@material-ui/core';

import authService from './AuthorizeService';
import useAuthenticated from './../hooks/useAuthenticated';
import { ApplicationPaths } from './ApiAuthorizationConstants'

const useStyles = makeStyles((theme) => ({
    link: {
        margin: theme.spacing(1, 1.5),
        color: '#f5f5f5',
        textTransform: 'none'
    },
    linkLogin: {
        margin: theme.spacing(1, 1.5),
        color: '#f5f5f5'
    },
}));

export default function LoginMenu() {

    const classes = useStyles();

    const { isAuthenticated } = useAuthenticated();
    const [userName, setUserName] = useState('');

    const fetchUserName = async () => {
        const token = await authService.getAccessToken();

        fetch('/api/user', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        })
            .then(r => r.json())
            .then(d => setUserName(d.fullName))
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        fetchUserName();
    }, []);

    function authenticatedView(profilePath, logoutPath) {
        return (
            <nav>
                <Button component={Link} to={profilePath} className={classes.link}>
                    HELLO {userName && userName.toUpperCase()}
                </Button>
                <Hidden only="xs">
                    <Button component={Link} to={logoutPath} className={classes.linkLogin}>
                        LOGOUT
                    </Button>
                </Hidden>
            </nav>
        );
    }

    function View() {
        if (isAuthenticated) {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return authenticatedView(profilePath, logoutPath);
        }
        return (<nav>
            <Button component={Link} to={`${ApplicationPaths.Login}`} className={classes.linkLogin}>
                LOGIN
            </Button>
        </nav>);
    }

    return (<View />);
}

