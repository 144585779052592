import React, { useState } from 'react';
import { useQuery, useMutation } from "react-query";
import { useParams, useHistory } from 'react-router-dom';
import ReactMarkdown from "react-markdown";
import StarIcon from '@material-ui/icons/Star';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {
    Typography,
    Container,
    Card,
    CardMedia,
    CardContent,
    Button,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Box,
    Select,
    MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Post } from '../query';
import Loader from './Loader';
import Error from './Error';
import FaqAndTncDialog from './FaqAndTncDialog';
import Listings, { Venues } from './Listings';

const rewardHeroImagePath = 'assets/img/rewards-hero/'

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        }
    },
    root: {
        flexGrow: 1,
    },
    toolbarTitle: {
        flexGrow: 1,
        margin: '25px 20px 20px 20px',
        fontWeight: 'bold'
    },
    cardMedia: {
        minHeight: '520px',
        maxWidth: '100%'
    },
    redeemBtn: {
        marginTop: theme.spacing(1)
    },
    backBtn: {
        margin: '0 20px 20px 20px'
    }
}));

const Redeem = (props) => {
    switch (props.reward.type) {
        case "PartnerSite":
        case "MotorEasy":
        case "UniqueCode":
        case "GenericCode":
        case "RciHotel":
        case "Redu":
           return <RedeemButton {...props} />
        case "Listing":
            return <Listings {...props} />
        default:
            return (<div>Please call our customer service team on the number listed below.</div>);
    }
}

const RedeemButton = ({ reward }) => {

    const classes = useStyles();
    const mutation = useMutation(() => Post(`/rewards/${reward.id}/redeem`));

    const handleRedeem = () => {
        mutation.mutate();
    }

    return (
        <>
            <Box mt={2}>
                {mutation.isLoading && <Loader />}
                {mutation.isError && <Error />}

                {mutation.isSuccess && <RedeemResult data={mutation.data} reward={reward} />}

                <div>
                    <Button
                        className={classes.redeemBtn}
                        disabled={mutation.isLoading || mutation.isSuccess}
                        variant="contained"
                        color="secondary"
                        onClick={handleRedeem}
                        startIcon={<StarIcon />}
                        fullWidth>
                        {
                            mutation.isLoading
                                ? '...' : mutation.isSuccess
                                ? 'Redeemed' : 'Redeem'
                        }
                    </Button>
                </div>
            </Box>
        </>
    );
};

const RedeemResult = (props) =>  {
    switch (props.data.type) {
        case "PartnerSite":
        case "MotorEasy":
        case "RciHotel":
        case "Redu":
            return <Link {...props} />
        case "UniqueCode":
        case "GenericCode":
            return <GenericCode {...props} />
        default:
            return (<div>Reward Type not found</div>);
    }
};

const Link = ({ data }) => {

    const openNewTab = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    }

    return (<div>{openNewTab(data.partnerSiteUrl)}</div>);
}

const GenericCode = ({ data }) => {
    return (
        <>
            <Typography variant="subtitle1" gutterBottom display="inline">
                Your unique code is:&nbsp;
            </Typography>
            <Typography variant="h5" gutterBottom display="inline">
                {data.code}
            </Typography>
        </>
    );
}

export default function () {

    const history = useHistory();
    const { reward } = useParams();
    const { isLoading, error, data: rewardObj } = useQuery(`/rewards/${reward}`);

    const [region, setRegion] = useState('');

    const classes = useStyles();

    const handleChangeRegion = (e) => {
        setRegion(e.target.value);
    }

    const IsListing = (value) => value === "Listing";

    if (isLoading) return <Loader/>;

    if (error) return <Error />;

    document.title = `Super Kids Club \u00bb ${rewardObj.title}`;

    return <>

        <Typography variant="h4" className={classes.toolbarTitle}>
            {rewardObj.title}
        </Typography>

        <Button
            className={classes.backBtn}
            variant="contained"
            color="default"
            startIcon={<NavigateBeforeIcon />}
            onClick={() => history.goBack()}
            >
            Back to All Rewards
        </Button>

        <Container component="main">

            <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                    <Card>
                        <CardMedia className={classes.cardMedia}
                            image={rewardHeroImagePath + rewardObj.largeImageUrl}
                            title={rewardObj.title}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Card>
                        <CardContent>
                            <ReactMarkdown source={rewardObj.description} />

                            <FaqAndTncDialog data={rewardObj} />
                            {
                                !IsListing(rewardObj.type) &&
                                <div className={classes.root}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Redeem reward={rewardObj} />
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        </CardContent>
                    </Card>
                    {
                        IsListing(rewardObj.type) &&
                        <Card>
                            <CardContent>

                                <div className={classes.root}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Listings reward={rewardObj} handleChangeRegion={handleChangeRegion} />
                                        </Grid>
                                    </Grid>
                                </div>

                            </CardContent>
                        </Card>
                    }
                    {
                        IsListing(rewardObj.type) && region &&
                        <Card>
                            <CardContent>

                                <div className={classes.root}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Venues rewardId={rewardObj.code} region={region} />
                                        </Grid>
                                    </Grid>
                                </div>

                            </CardContent>
                        </Card>
                    }
                </Grid>
            </Grid>

        </Container>
    </>;
}