import React from 'react';
import { Route, BrowserRouter as Router } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import NavMenu from './NavMenu';
import AppBanner from './AppBanner';
import Footer from './Footer';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
    },
    main: {
    },
    footer: {
        marginTop: 'auto'
    },
}));

export default function Layout(props) {

    const classes = useStyles();

    return (
        <Router>
            <div className={classes.root}>
                <Container maxWidth="xl" disableGutters>
                    <NavMenu />
                    <Route exact path="/" component={AppBanner} /> {/* Conditionally Render on Home Page Only */}
                    <Container maxWidth="lg" disableGutters>
                        {props.children}
                    </Container>
                </Container>
                <footer className={classes.footer}>
                    <Footer/>
                </footer>
            </div>
        </Router>
    );
}

